<template>
  <div class="remaining_sum">
    <a-modal
      :destroyOnClose="true"
      :maskClosable="false"
      :visible="visible"
      cancelText="取消"
      centered
      dialogClass="payment-method-modal"
      okText="确定"
      title="余额支付"
      @cancel="closeModal"
      @ok="handleOk"
    >
      <div class="vessel">
        <h5 style="color: #fc591b;text-align: center;margin-bottom: 20px;">需要支付总金额为：￥{{ consumptionPrice }}</h5>
        <a-form :form="form" >
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="手机号："
          >
            <a-input
              v-model="userInfoPhone"
              :disabled="true"
              allow-clear />
          </a-form-item>
          <a-form-item
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
            label="验证码："
          >
            <a-row :gutter="20">
              <a-col :span="18">
                <a-input
                  v-decorator="['code', { rules: [{ required: true, message: '请输入验证码' }]}]"
                  allow-clear
                  placeholder="验证码"
                  style="width: 100%"/>
              </a-col>
              <a-col :span="2">
                <el-button :disabled="code.status == 1" size="small" style="background-color: #205fb9;" type="primary" @click="getCode">
                  {{codeText}}
                </el-button>
              </a-col>
            </a-row>

          </a-form-item>
        </a-form>
      </div>
      <template slot="footer">

      </template>

    </a-modal>
  </div>
</template>

<script>
import ShowModalMixin from '@/mixin/ShowModalMixin'
import { yuePay } from '@/api/Order'

const fields = ['code']

export default {
  name: 'RemainingSumPay',
  props: {
    /* 总消费支付余额 */
    consumptionPrice: {
      type: [String, Number],
      defaults: ''
    },
    /* 采购商电话号码 */
    userInfoPhone: {
      type: String,
      default: ''
    }
  },
  mixins: [ ShowModalMixin ],
  data() {
    return {
      labelCol: {
        xs: {span: 24},
        sm: {span: 7}
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 13}
      },
      visible: false,
      form: this.$form.createForm(this),
      cellPhone: '',
      code: {
        status: 0, // 0 获取验证码 1 已发送{count}秒 2 重新发送
        count: '',
        timer: null,
        uuid: '',
      },
    }
  },
  watch: {
    consumptionPrice(newValue, oldValue) {
      if (newValue) return newValue.toFixed(2)
    }
  },
  computed: {
    codeText() {
      if (this.code.status === 1) {
        return `验证码已发送 ${this.code.count}秒`
      } else if (this.code.status === 2) {
        return `重新发送`
      } else {
        return `获取验证码`
      }
    }
  },
  created() {
    fields.forEach(v => this.form.getFieldDecorator(v))
  },
  methods: {
    handleOk() {
      const { form: { validateFields } } = this
      validateFields((err, values) => {
        if (!err) {
          console.log(values, '关闭值')
          this.code['code'] = values.code || this.form.getFieldValue('code')
          this.$emit('ok', this.code)
          this.closeModal()
        }
      })
    },
    // 获取验证码触发事件
    getCode() {
      const times = 60 // 倒计时时间
      if (!this.timer) {
        yuePay().then((res) => {
          this.$message.success('发送验证码成功')
          console.log('余额支付验证码', res)
          if (res.ok) this.code.uuid = res.data
        }).catch(() => {
          this.code.status = 2
          clearInterval(this.code.timer)
          this.code.timer = null
        })
        this.code.count = times
        this.code.status = 1
        this.code.timer = setInterval(() => {
          if (this.code.count > 0 && this.code.count <= times) {
            this.code.count--
          } else {
            this.code.status = 2
            clearInterval(this.code.timer)
            this.code.timer = null
          }
        }, 1000)
      }
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
