<template>
  <div class="order bg-white p-10">
    <OrderTable></OrderTable>
  </div>
</template>

<script>
import { userNavOptions } from '@/views/User/userNavOptions'
import DefaultNavigation from '@/components/defaultNavigation'
import Shortcuts from '@/components/Shortcuts'
import OrderTable from '@/views/User/Order/OrderTable/OrderTable'
/**
 * @author  XuHongli
 * @date  2022/8/30 16:07
 * @version 1.0
 * @description 用户订单页面
 */
export default {
  name: 'Order',
  components: { OrderTable, DefaultNavigation, Shortcuts },
  data() {
    return {
      userNavOptions,
      shortcutMenus: [{
        titleName: '我的订单',
        url: '/user/order'
      }]
    }
  },
}
</script>

<style lang="scss" scoped>
.order {
  // background-color: rgb(240,240,240) !important;
}

.content {
  display: flex;

  .right-content {
    margin-left: 30px;
    width: 100%;
    min-width: 800px;
    // background-color: #fff;
    justify-content: flex-end;
  }
}
</style>
